<template>
  <div
    class="ecommerce-application"
    style="height: inherit"
  >

    <b-row>
      <b-col>    <!-- ECommerce Header -->

        <!-- Overlay -->
        <div class="body-content-overlay" />

        <!-- Searchbar -->
        <b-overlay :show="showS">
          <section :class="itemView">
            <b-card
              v-for="product in products_all"
              :key="product.id"
              class="ecommerce-card"
              no-body
            >
              <div class="item-img text-center pt-0">
                <b-img
                  :alt="`${product.name}-${product.id}`"
                  fluid
                  class="card-img-top"
                  style="width: 100%; height: 250px; object-fit: contain"
                  :src="product.photo.thumb_url"
                />
              </div>

              <!-- Product Details -->
              <b-card-body>
                <div class="item-wrapper">
                  <!-- <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div> -->
                  <div>
                    <h6>
                      {{ product.category_name }}
                    </h6>
                  </div>
                  <div>
                    <h6 class="item-price">
                      ₺{{ product.list_price }}
                    </h6>
                  </div>
                </div>
                <h6 class="item-name">
                  <b-link
                    class="text-body"
                  >
                    {{ product.name }}
                  </b-link>
                  <b-card-text class="item-company">
                    Ürün Kodu:
                    {{ product.code }}
                  </b-card-text>
                </h6>
                <b-card-text class="item-description">
                  <!-- {{ product.description }} -->
                  <!-- Vergi Oranı: {{ product.vat_rate }}<br>
                Stok Miktarı: {{ Number(product.stock_count).toFixed(0) }}<br> -->
                  Ürün Birimi: {{ product.unit }}
                  <br>
                  Stok kodu: {{ product.code }}
                </b-card-text>
              </b-card-body>

              <!-- Product Actions -->
              <div class="item-options text-center">
                <div class="item-wrapper">
                  <div class="item-cost">
                    <h4 class="item-price">
                      ₺{{ product.list_price }}
                    </h4>
                  </div>
                </div>
              </div>
            </b-card>
          </section>
        </b-overlay>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BLink, BImg, BCardText, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BOverlay,
    BCardText,
    // BPagination,

    // SFC
  },
  data() {
    return {
      products_all: [],
      showS: false,
      itemView: 'grid-view',
    }
  },
  computed: {
    ...mapGetters('product', ['getProductsData', 'getProperties', 'getCartProducts']),
    // ...mapGetters('auth', ['IS_LOGIN']),
  },
  created() {
    this.filterProducts()
  },
  methods: {
    ...mapActions('product', ['getProducts']),
    filterProducts(flt = null) {
      this.showS = true
      this.getProducts(flt).then(() => {
        this.products_all = this.getProductsData
      })
      this.showS = false
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce";

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
